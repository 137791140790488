form {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 300px;
    background-color: white;
    padding: 30px;
    border-radius: 3px;
}
label {
    color: black;
    font-weight: 500;
    font-size: 20px;
    font-family: 'Montserrat';
}
textarea {
    resize: none;
    height: 100px;
    width: 80%;
    font-size: 16px;
    padding: 10px;
    border-radius: 2px;
    font-family: 'Montserrat';
}
.button-container {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
}
.button {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    font-family: 'Montserrat';
    border-radius: 2px;
    border: 0px solid;
    box-shadow: 5px 5px 50px rgb(174, 174, 174);
    font-size: 15px;
}
.button-submit {
    background-color: #61DAFA;
    color: white;
}