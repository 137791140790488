.modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.682);
    position: fixed;
    top: 0;   
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal div {
    color: white;
    font-size: 30px;
    font-weight: 300;
}