@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

input {
    width: 279px;
    min-height: 64px;
    border-radius: 2px;
    border-color: #1E1E1F;
    text-align: center;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 18px;
    box-shadow: 5px 5px 50px rgba(32, 35, 41, 0.25); /* offset-x, offset-y, blur, color. Blur:50 actually covers it all. */

}
input::placeholder {
    color: #A5A5A5;
}