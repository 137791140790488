

.create-todo-button {
    background-color:  #61DAFA;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    box-shadow: 0 5px 25px #61DAFA80;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 25px;
    right: 20px;
    z-index: 2;
    transform: rotate(0deg);
    /* Using rotation effect for closing and opening toggle. There must be a 0deg transition for it to close smoothly. */
    transition: transform 0.5s;
    cursor: pointer;


}
.open {
    /* Remember the pairing closing (default) transition in default state style. */
    transform: rotate(45deg);
    transition: transform 0.5s;
}