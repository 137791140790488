li {
    position: relative;
    /* Has to be here for child position absolute to work. */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* Aligned vertically. */
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 12px;
    background-color: #FAFAFA;
    box-shadow: 5px 5px 50px rgba(30, 30, 31, 0.15);
    width: 80%;
    height: 50px;
    gap: 20px;

}

li p {
    margin: 0;
}

.item-completed {
    text-decoration: line-through;
}
/* How the item must look if charging - part of loading skeleton. */
.loading-screen {
    border-radius: 2px;
    width: 80%;
    height: 25px;
    max-width: 600px;
    padding-top: 24px;
    padding-bottom: 24px;
    /* border: 7px solid #e2e2e2; */
    box-sizing: content-box;
    /* background-color: #ffffff; */
    background: linear-gradient(45deg, #FAFAFA, #f5f5f5);
}
/* Loading state (skeleton) items. */
.loading-screen div {
    width:20px;
    height:20px;
    background: #d4d4d4;
    border-radius: 50px;
}
.loading-screen span {
    width: 70%;
    height: 20px;
    background: #d4d4d4;
}
