.icon-container {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    font-size: 24px;
    font-weight: bold;
}

.icon-svg {
    width: 24px;
    height: 24px;
}

.icon-container-check {
    position: relative;
}

.icon-container-check--active {
    color: #4caf50;
}

.icon-container-delete {
    position: absolute;
    top: -7px;
    right: -7px;
}

.icon-container-delete:hover {
    color: red;
}

.icon-container-check:hover .icon-svg {
    fill: green;
}

.icon-container-delete:hover .icon-svg {
    fill: red;
}