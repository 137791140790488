/**
  CREATED BY MOI
*/

/*
    Color de fondo para la app.
*/
body {
    background-color: #FAFAFA;
    padding: 15px 35px 20px 35px; /* Top Left Down Right */
    /* Setting to min-height allows for it to grow; setting to height makes things inside compress. */
    min-height: 100vh;
    padding: 0;
  }
  /*
    Layout del contenedor raiz de React.
  */
  #root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Aligned horizontally */
    height: 100vh;
    padding-left: 10px;
    padding-right: 10px;
  }