@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

h1 {
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    padding-left: 3px;
    padding-right: 3px;
}
